<template>
  <div class="users">
    <div class="row margin-0">
      <div class="col-md-12 pad-15">
        <div class="parent margin-bottom-15" v-if="visible_form">
          <div class="parent-body pad-0">
            <div class="row margin-0">
              <div class="col pad-0">
                <h4 v-if="!payload.created_on" class="text-center">Add New Banner</h4>
                <h4 v-if="payload.created_on" class="text-center">Update Banner</h4>
                <div class="row margin-0 margin-bottom-5">
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Banner Title</b></label>
                      <input
                        type="text"
                        v-model="payload.title"
                        class="form-control form-control-sm"
                        placeholder="Title"
                      />
                      <span class="error" v-if="error && error.title">{{
                        error.title
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Category</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.category"
                        class="form-control form-control-sm"
                      >
                        <option value="PROMOTIONS">PROMOTIONS</option>
                        <option value="OFFER">OFFER</option>
                      </select>
                      <span class="error" v-if="error && error.category">{{
                        error.category
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>target</b></label>
                      <select
                        name=""
                        id=""
                        v-model="payload.target"
                        class="form-control form-control-sm"
                      >
                        <option value="HOMEPAGE">HOMEPAGE</option>
                        <option value="DASHBOARD">DASHBOARD</option>
                        <option value="WALLET">WALLET</option>
                        <option value="MEMBERSHIP">MEMBERSHIP</option>
                        <option value="APP_HOMEPAGE">APP HOMEPAGE</option>
                        <option value="APP_DASHBOARD">APP DASHBOARD</option>
                        <option value="APP_WALLET">APP WALLET</option>
                        <option value="APP_MEMBERSHIP">APP MEMBERSHIP</option>
                      </select>
                      <span class="error" v-if="error && error.target">{{
                        error.target
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>order</b></label>
                      <input
                        type="text"
                        v-model="payload.order"
                        class="form-control form-control-sm"
                        placeholder="Order"
                      />
                      <span class="error" v-if="error && error.order">{{
                        error.order
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Starts Date</b></label>
                      <input
                        type="datetime-local"
                        v-model="payload.start_date"
                        class="form-control form-control-sm"
                        placeholder="Start Date"
                      />
                      <span class="error" v-if="error && error.start_date">{{
                        error.start_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>End Date</b></label>
                      <input
                        type="datetime-local"
                        v-model="payload.expiry_date"
                        class="form-control form-control-sm"
                        placeholder="End Date"
                      />
                      <span class="error" v-if="error && error.expiry_date">{{
                        error.expiry_date
                      }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="form-group">
                      <label for=""><b>Status</b></label>
                      <select name="" id="" v-model="payload.status" class="form-control form-control-sm">
                        <option value="true">Active</option>
                        <option value="false">InActive</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for=""><b>Banner</b></label><br>
                      <img :src="payload.banner" class="img-fluid" style="max-height:10vh;" alt=""><br>
                      <input type="text" v-model="payload.banner" class="form-control form-control-sm">
                      <input type="file" @change="uploadFile()" id="imgfile" class="form-control form-control-sm">
                      <span v-if="uploadingStatus">{{uploadingStatus}}</span><br>
                      <span class="error" v-if="error && error.banner">{{error.banner}}</span>
                    </div>
                  </div>
                  <div class="col-12 text-right">
                    <button
                      v-if="!payload.id"
                      @click="newData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Create Banner
                    </button>
                    <button
                      v-if="payload.id"
                      @click="updateData()"
                      class="btn btn-success btn-sm margin-right-10"
                    >
                      Update Banner
                    </button>
                    <button
                      @click="visible_form = false"
                      class="btn btn-light btn-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="parent">
          <div class="parent-head">
            <ul>
              <li :class="{ active: tab === 'Courses' }">Banners</li>
            </ul>
          </div>
          <div class="parent-body">
            <div class="row margin-0 margin-bottom-5">
              <div class="col-2 pad-0">
                <div class="form-group">
                  <label for=""><b>Search Word</b></label>
                  <input
                    type="text"
                    v-model="search_term"
                    placeholder="Search Word"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <br />
                  <button
                    @click="getdataList()"
                    class="btn btn-primary btn-sm margin-right-10"
                  >
                    Search
                  </button>
                  <button @click="clearFilter()" class="btn btn-light btn-sm">
                    Clear
                  </button>
                </div>
              </div>
              <div class="col text-right pad-0">
                <div class="form-group">
                  <br />
                  <button
                    @click="
                      visible_form = true;
                      resetPayload();
                    "
                    class="btn btn-primary btn-sm"
                  >
                    + New Banner
                  </button>
                </div>
              </div>
            </div>
            <div class="text-right">
              Banner size: 1600x300 pixels, 16:3 ratio
            </div>
            <table class="table sai-table fixed">
              <thead>
                <tr class="t-head">
                  <th>title</th>
                  <th>category</th>
                  <th>target</th>
                  <th>order</th>
                  <th>banner</th>
                  <th>start_date</th>
                  <th>expiry_date</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="!loading_list">
                  <template v-for="item in dataList.results" :key="item.id">
                    <tr>
                      <td>{{item.title}}</td>
                      <td>{{item.category}}</td>
                      <td>{{item.target}}</td>
                      <td>{{item.order}}</td>
                      <td><img v-if="item.banner" :src="item.banner" style="width:5vw;" alt="" srcset=""></td>
                      <td>{{$filters.fullDate(item.start_date, 'DD MMM, YYYY, h:mm A')}}</td>
                      <td>{{$filters.fullDate(item.expiry_date, 'DD MMM, YYYY, h:mm A')}}</td>
                      <td>
                        <span class="text-success" v-if="item.status"><b>Active</b></span>
                        <span class="text-warning" v-else><b>Inactive</b></span>
                        <span
                          class="fa fa-pencil edit-icon f-right"
                          style="cursor pointer;padding:5px;"
                          @click="dataEdit(item)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
              <footer-table
                :data="dataList"
                :loading="loading_list"
                :pageSize="pageSize"
                @action="handleTableAction"
                cols="6"
              ></footer-table>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataMixin from "../mixins/dataMixin";
export default {
  data() {
    return {
      serviceModule: "banner",
      defaultPayload: {
        title: null,
        category: "PROMOTIONS",
        target: "DASHBOARD",
        order: 1,
        banner: null,
        start_date: null,
        expiry_date: null,
        status: true,
      },
    };
  },
  mixins: [DataMixin],
  methods: {
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (
        that.payload.title === null ||
        that.payload.title === ""
      ) {
        this.error.title = "title is required";
        proceed = false;
      }
      if (
        that.payload.target === null ||
        that.payload.target === ""
      ) {
        this.error.target = "target is required";
        proceed = false;
      }
      if (
        that.payload.banner === null ||
        that.payload.banner === ""
      ) {
        this.error.banner = "banner is required";
        proceed = false;
      }
      if (
        that.payload.start_date === null ||
        that.payload.start_date === ""
      ) {
        this.error.start_date = "start date is required";
        proceed = false;
      }
      if (
        that.payload.expiry_date === null ||
        that.payload.expiry_date === ""
      ) {
        this.error.expiry_date = "end date is required";
        proceed = false;
      }
      if(proceed) {
        that.payload.start_date = that.$moment(that.payload.start_date).format('YYYY-MM-DD HH:MM');
        that.payload.expiry_date = that.$moment(that.payload.expiry_date).format('YYYY-MM-DD HH:MM');
      }
      console.log(this.error);
      return proceed;
    },
    uploadFile() {
      const that = this;
      const File = document.getElementById('imgfile').files[0];
      if (File !== undefined) {
        that.uploadingStatus = 'Uploading....';
        that.$cs.img.upload(File, 'banner').then(res => {
          that.payload.banner = res;
          that.uploadingStatus = "Uploaded Successfully";
        }, err => {
          that.uploadingStatus = err;
        });
      }
    }
  },
};
</script>
<style lang="stylus" scoped></style>